import React from 'react';
import "./JumboTron.css";



const JumboTron = () => {
    return(
        <div className="jumboTron"> 
            <div className="image">
                <div>Full Stack Developer</div>
            </div>
        </div>
    )
}

export default JumboTron;