import React from 'react';
import './Header.css';


const Header = () => {
    return (
        <div className="header">
            <span>Alvaro Martinez  </span>
           
        </div>
    )
}





export default Header;