import React from 'react';
import './Contact.css';



const Contact = () => {

    return(
        <div className="contact">
            <div className="title">Contact</div>
            <div>Phone: 973.449.0532</div>
            <div>Email: alcreates101@gmail.com</div>
            <div>GitHub: https://github.com/alcreates</div>
        </div>
    )
}


export default Contact;
